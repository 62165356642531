import React from "react"
import { Helmet } from "react-helmet"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import {
  Banner,
  BannerStyle,
  Content,
  PaddingHalfWrapper,
  PaddingWrapper,
} from "@igloonet-web/shared-ui"

import { BankInfo } from "./kontakt/bankovni-spojeni"

const BankovniSpojeni: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Payment Information | igloonet</title>
    </Helmet>
    <Banner
      style={BannerStyle.Reference}
      orangeHeading="Payment Information"
      text="For your convenience, there are several bank accounts available."
    />
    <PaddingWrapper>
      <Container>
        <h2>Payments from the Czech Republic</h2>

        <div>
          <div className="font-weight-bold">Raiffeisenbank a.s.</div>
          <Content noPadding>
            <ul>
              <li>
                <b>CZK and EUR: </b> <code>7261610001/5500</code>
              </li>
            </ul>
          </Content>
        </div>

        <p className="ml-3">or</p>

        <div>
          <div className="font-weight-bold">Fio banka, a.s.</div>
          <Content noPadding>
            <ul>
              <li>
                <b>CZK: </b> <code>2500090814/2010</code>
              </li>
              <li>
                <b>EUR: </b> <code>2600104558/2010</code>
              </li>
            </ul>
          </Content>
        </div>

        <PaddingHalfWrapper>
          <h2 className="mt-5">International Payments - EUR only</h2>
          <Content noPadding>
            <ul>
              <li>
                <b>EU Countries: </b>
                payments can be made{" "}
                <b>only in SEPA mode with the SHA disposition (Euro Payment)</b>
                .
              </li>
              <li>
                <b>Other countries: </b>
                payments can be made <b>only with SHA disposition</b>.
              </li>
            </ul>
            <ul>
              <li>
                Please enter the variable symbol in the <b>Payment Reference</b>{" "}
                field and also in the <b>Message for Recipient</b>.
              </li>
            </ul>
          </Content>

          <BankInfo>
            <div className="inner">
              <div className="key">Bank Name:</div>
              <div className="value">
                <b>Fio banka, a.s.</b>,<br />
                <em>
                  V Celnici 1028/10,
                  <br />
                  Prague 1,
                  <br />
                  117 21,
                  <br />
                  Czech Republic (CZ)
                </em>
              </div>
              <div className="key">IBAN:</div>
              <div className="value">
                <code>CZ7720100000002600104558</code>
              </div>
              <div className="key">BIC/SWIFT:</div>
              <div className="value">
                <code>FIOBCZPPXXX</code>
              </div>
            </div>
          </BankInfo>

          <p className="mt-3 ml-3">or</p>

          <BankInfo>
            <div className="inner">
              <div className="key">Bank Name:</div>
              <div className="value">
                <b>Raiffeisenbank a.s.</b>,<br />
                <em>Prague, Czech Republic</em>
              </div>
              <div className="key">IBAN:</div>
              <div className="value">
                <code>CZ4055000000007261610001</code>
              </div>
              <div className="key">BIC/SWIFT:</div>
              <div className="value">
                <code>RZBCCZPP</code>
              </div>
            </div>
          </BankInfo>
        </PaddingHalfWrapper>
      </Container>
    </PaddingWrapper>
  </Layout>
)

export default BankovniSpojeni
